<template>
  <div
    class="file-upload"
    @dragover.prevent
    @dragenter.prevent
    @drop.prevent="handleDrop"
  >
    <div
      class="drop-area"
      :class="{ 'is-dragover': isDragOver }"
      @dragover.prevent="isDragOver = true"
      @dragleave.prevent="isDragOver = false"
    >
      <p>Drag & Drop your invoice here</p>
      <p>or</p>
      <button @click="triggerFileInput">Choose File</button>
      <input
        type="file"
        ref="fileInput"
        @change="handleFileSelect"
        style="display: none;"
        accept=".pdf, .jpeg, .jpg, .gif, .png"
      />
    </div>
    <div v-if="file">
      <p>Selected File: {{ file.name }}</p>
      <button @click="uploadFile">Upload</button>
    </div>
    <div v-if="uploadProgress > 0">
      <p>Upload Progress: {{ uploadProgress }}%</p>
    </div>
    <div v-if="message">
      <p>{{ message }}</p>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'FileUpload',
  data() {
    return {
      file: null,
      isDragOver: false,
      uploadProgress: 0,
      message: '',
    };
  },
  methods: {
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    handleFileSelect(event) {
      const files = event.target.files;
      if (files.length > 0) {
        this.setFile(files[0]);
      }
    },
    handleDrop(event) {
      this.isDragOver = false;
      const files = event.dataTransfer.files;
      if (files.length > 0) {
        this.setFile(files[0]);
      }
    },
    setFile(file) {
      const allowedTypes = [
        'application/pdf',
        'image/jpeg',
        'image/jpg',
        'image/png',
        'image/gif',
      ];
      if (!allowedTypes.includes(file.type)) {
        this.message = 'Please upload a PDF or image file (.jpeg, .jpg, .gif, .png).';
        this.file = null;
        return;
      }
      this.file = file;
      this.message = '';
    },
    async uploadFile() {
      if (!this.file) {
        this.message = 'No file selected.';
        return;
      }

      const formData = new FormData();
      formData.append('file', this.file);

      try {
        await axios.post('/api/upload-invoice', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: (progressEvent) => {
            this.uploadProgress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
          },
        });
        this.message = 'File uploaded successfully.';
        this.uploadProgress = 0;
        this.file = null;
      } catch (error) {
        console.error('Upload error:', error);
        this.message = 'File upload failed.';
        this.uploadProgress = 0;
      }
    },
  },
};
</script>

<style scoped>
.file-upload {
  width: 400px;
  margin: 50px auto;
  text-align: center;
}

.drop-area {
  border: 2px dashed #ccc;
  padding: 20px;
  position: relative;
  cursor: pointer;
}

.drop-area.is-dragover {
  border-color: #333;
}

.drop-area p {
  margin: 0;
}

button {
  margin-top: 10px;
}
</style>
